import React from 'react'
import userimg from '../assets/user_1.jpeg'

const appIcon =
    <svg className='w-6 h-6  mr-2' viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2_7)">
            <path d="M32.4908 2.8366C33.2578 3.72605 33.116 5.02414 32.1685 5.7357L21.856 13.9283C21.1793 14.5729 20.1416 14.5922 19.3875 14.025L12.4137 8.79787L3.35091 15.9908C2.46145 16.7578 1.16337 16.616 0.451998 15.6686C-0.259629 14.8371 -0.115383 13.5416 0.774071 12.8262L11.0859 4.57683C11.8207 3.98967 12.8584 3.97355 13.6125 4.53752L20.5863 9.76467L29.6484 2.51433C30.5379 1.80277 31.8334 1.94715 32.4908 2.8366Z" fill="#4176DA" />
            <path opacity="0.4" d="M14.4375 16.5V28.875C14.4375 30.0158 13.5158 30.9375 12.375 30.9375C11.2342 30.9375 10.3125 30.0158 10.3125 28.875V16.5C10.3125 15.3592 11.2342 14.4375 12.375 14.4375C13.5158 14.4375 14.4375 15.3592 14.4375 16.5ZM6.1875 22.6875V28.875C6.1875 30.0158 5.26389 30.9375 4.125 30.9375C2.98611 30.9375 2.0625 30.0158 2.0625 28.875V22.6875C2.0625 21.5467 2.98611 20.625 4.125 20.625C5.26389 20.625 6.1875 21.5467 6.1875 22.6875ZM18.5625 20.625C18.5625 19.4842 19.4842 18.5625 20.625 18.5625C21.7658 18.5625 22.6875 19.4842 22.6875 20.625V28.875C22.6875 30.0158 21.7658 30.9375 20.625 30.9375C19.4842 30.9375 18.5625 30.0158 18.5625 28.875V20.625ZM30.9375 16.5V28.875C30.9375 30.0158 30.0158 30.9375 28.875 30.9375C27.7342 30.9375 26.8125 30.0158 26.8125 28.875V16.5C26.8125 15.3592 27.7342 14.4375 28.875 14.4375C30.0158 14.4375 30.9375 15.3592 30.9375 16.5Z" fill="#4176DA" />
        </g>
    </svg>


const Header = () => {
    return (
        <div className='border-b  bg-white border-gray-100'>
            <div className='max-w-6xl mx-auto px-6 py-4 flex items-center justify-between'>
                <div>
                    <span className='font-bold text-lg flex items-center'>{appIcon}Fund Outlook Modeling</span>
                </div>
                <div className='flex items-center space-x-10'>
                    <span className='text-xs lg:text-sm hidden md:block'><span className='block text-gray-500'>Program</span><span className='text-gray-900 font-medium'>CPE</span></span>
                    <span className='hidden md:block text-xs lg:text-sm'><span className='block text-gray-500'>Valuation Year</span><span className='text-gray-900 font-medium'>2018-2019 - CPE(138)</span></span>
                    <div className='md:border-l border-gray-100 ml-4 md:pl-10 relative'>

                        <img alt="User" className="w-10 h-10 rounded-full border border-gray-100 relative hover:opacity-50 transition cursor-pointer" src={userimg} />
                        <span className='w-2 h-2 bg-green-500 absolute rounded-full right-0 bottom-1 z-10' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header