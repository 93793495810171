import React, { Fragment, useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import Header from './components/header'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import Button from '../../atoms/Button'

 

export default function FundOutlook() {

    useEffect(() => {
        console.log("use effect running")
        document.title = "Fund Outlook Modeling"
    }, [])

    let years = [10, 20, 30]
    let perpa = ['Yes', 'No']
    let transformYearsOptions = ['10 Years', '15 Years', '20 Years']
    let addDiscretionaryPaymentsOptions = ['Yes', 'No']
    let randomScenarioOptions = ['Yes', 'No']
    let randomInvestementPercentileOptions = ['0-33%', '33-66%', '66-100%']

    const [modelingLoading, setModelingLoading] = useState(false)
    const [yearsToProject, setYearsToProject] = useState(years[0])
    const [perpaSelection, setPerpaSelection] = useState(perpa[0])
    const [transformYears, setTransformYears] = useState(transformYearsOptions[1])
    const [addDiscretionaryPayments, setAddDiscretionaryPayments] = useState(addDiscretionaryPaymentsOptions[0])
    const [randomScenario, setRandomScenario] = useState(randomScenarioOptions[0])
    const [randomInvestementPercentile, setRandomInvestementPercentile] = useState(randomInvestementPercentileOptions[0])

    const [discountRate, setDiscountRate] = useState('7.000')




    return (
        <div className='bg-gray-50'>
            <Header />
            <div className='max-w-6xl mx-auto px-6 py-6 border-b border-gray-100'>
                <span className='font-bold flex items-center mb-4 md:text-lg'>Model Assumptions</span>

                <div className='grid grid-cols-2 md:grid-cols-4 gap-6'>
                    <div className='space-y-6'> 
                    <RadioGroup value={yearsToProject} onChange={setYearsToProject}>
                        <RadioGroup.Label className='block font-medium text-gray-800 text-sm mb-2'>Years to Project</RadioGroup.Label>
                        <div className="flex">
                            {years.map((year, i) => (
                                <RadioGroup.Option
                                    key={year}
                                    value={year}
                                    className={({ active, checked }) =>
                                        `   ${active
                                            ? 'z-10 ring-2 ring-white ring-opacity-100 ring-offset-2 ring-offset-blue-500'
                                            : ''
                                        }
                                            ${i === 0 ? 'rounded-l-lg' : ''}
                                            ${i === years.length - 1 ? 'rounded-r-lg' : ''}
                  ${checked ? 'bg-blue-50 text-blue-600 font-medium' : 'font-medium bg-white text-gray-600'
                                        }
                    relative flex cursor-pointer px-5 py-3 focus:outline-none text-sm `
                                    }
                                >
                                    <RadioGroup.Label as="p">
                                        {year}
                                    </RadioGroup.Label>
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>

                        <div>
                            <label htmlFor="discountRate" className="block text-sm font-medium text-gray-700">
                                Discount Rate %
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    value={discountRate}
                                    onChange={(e) => setDiscountRate(e.target.value)}
                                    name="discountRate"
                                    id="discountRate"
                                    className=" font-medium bg-white border border-gray-100 text-gray-600 px-5 py-3 focus:outline-none text-sm rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"

                                />
                            </div>
                        </div>
                    </div>
                    <div className='space-y-6'> 
                    <RadioGroup value={perpaSelection} onChange={setPerpaSelection}>
                        <RadioGroup.Label className='block font-medium text-gray-800 text-sm mb-2'>PERPA</RadioGroup.Label>
                        <div className="flex">
                            {perpa.map((perpa, i) => (
                                <RadioGroup.Option
                                    key={perpa}
                                    value={perpa}
                                    className={({ active, checked }) =>
                                        `  ${active
                                            ? 'z-10 ring-2 ring-white ring-opacity-100 ring-offset-2 ring-offset-blue-500'
                                            : ''
                                        }
                                            ${i === 0 ? 'rounded-l-lg' : ''}
                                            ${i === perpa.length - 1 ? 'rounded-r-lg' : ''}
                  ${checked ? 'bg-blue-50 text-blue-600 font-medium' : 'font-medium bg-white text-gray-600'
                                        }
                    relative flex cursor-pointer px-5 py-3 focus:outline-none text-sm border border-gray-100`
                                    }
                                >
                                    <RadioGroup.Label as="p">
                                        {perpa}
                                    </RadioGroup.Label>
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>

                        <div>
                            <Listbox value={transformYears} onChange={setTransformYears}>
                                {({ open }) => (
                                    <>
                                        <Listbox.Label className="block text-sm font-medium text-gray-700">Tranform Years</Listbox.Label>
                                        <div className="mt-1 relative">
                                            <Listbox.Button className="bg-white relative bg-white border border-gray-100 rounded-lg pl-3 pr-10 py-3 text-left cursor-default focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                                <span className="block truncate pr-12  text-gray-600  text-sm  font-medium ">{transformYears}</span>
                                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </span>
                                            </Listbox.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                    {transformYearsOptions.map((year) => (
                                                        <Listbox.Option
                                                            key={year}
                                                            className={({ active }) => (`${active ? 'text-white bg-blue-600' : 'text-gray-900'} cursor-default select-none relative py-2 pl-3 pr-9`)}
                                                            value={year}
                                                        >
                                                            {({ selected, active }) => (
                                                                <>
                                                                    <span className={(selected ? 'font-semibold block truncate' : 'font-normal block truncate')}>
                                                                        {year}
                                                                    </span>

                                                                    {selected ? (
                                                                        <span
                                                                            className={`  ${active ? 'text-white' : 'text-blue-600'} absolute inset-y-0 right-0 flex items-center pr-4`}
                                                                        >
                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </>
                                )}
                            </Listbox></div>


                    </div>
                    <div className='space-y-6'> 
                    <RadioGroup value={addDiscretionaryPayments} onChange={setAddDiscretionaryPayments}>
                        <RadioGroup.Label className='block font-medium text-gray-800 text-sm mb-2'>Additional Discretionary Payment</RadioGroup.Label>
                        <div className="flex">
                            {addDiscretionaryPaymentsOptions.map((paymentOption, i) => (
                                <RadioGroup.Option
                                    key={paymentOption}
                                    value={paymentOption}
                                    className={({ active, checked }) =>
                                        `  ${active
                                            ? 'z-10 ring-2 ring-white ring-opacity-100 ring-offset-2 ring-offset-blue-500'
                                            : ''
                                        }
                                            ${i === 0 ? 'rounded-l-lg' : ''}
                                            ${i === addDiscretionaryPaymentsOptions.length - 1 ? 'rounded-r-lg' : ''}
                  ${checked ? 'bg-blue-50 text-blue-600 font-medium' : 'font-medium bg-white text-gray-600'
                                        }
                    relative flex cursor-pointer px-5 py-3 focus:outline-none text-sm border border-gray-100`
                                    }
                                >
                                    <RadioGroup.Label as="p">
                                        {paymentOption}
                                    </RadioGroup.Label>
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>
                  
                        


                    </div>
                    <div className='space-y-6 col-span-2 md:col-span-1'> 
                    <RadioGroup value={randomScenario} onChange={setRandomScenario}>
                        <RadioGroup.Label className='block font-medium text-gray-800 text-sm mb-2'>Random Investement Scenario</RadioGroup.Label>
                        <div className="flex">
                            {randomScenarioOptions.map((scenario, i) => (
                                <RadioGroup.Option
                                    key={scenario}
                                    value={scenario}
                                    className={({ active, checked }) =>
                                        `  ${active
                                            ? 'z-10 ring-2 ring-white ring-opacity-100 ring-offset-2 ring-offset-blue-500'
                                            : ''
                                        }
                                            ${i === 0 ? 'rounded-l-lg' : ''}
                                            ${i === randomScenarioOptions.length - 1 ? 'rounded-r-lg' : ''}
                  ${checked ? 'bg-blue-50 text-blue-600 font-medium' : 'font-medium bg-white text-gray-600'
                                        }
                    relative flex cursor-pointer px-5 py-3 focus:outline-none text-sm border border-gray-100`
                                    }
                                >
                                    <RadioGroup.Label as="p">
                                        {scenario}
                                    </RadioGroup.Label>
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>

                    <RadioGroup value={randomInvestementPercentile} onChange={setRandomInvestementPercentile}>
                        <RadioGroup.Label className='block font-medium text-gray-800 text-sm mb-2'>Random Investement Percentile</RadioGroup.Label>
                        <div className="flex">
                            {randomInvestementPercentileOptions.map((percentile, i) => (
                                <RadioGroup.Option
                                    key={percentile}
                                    value={percentile}
                                    className={({ active, checked }) =>
                                        `  ${active
                                            ? 'z-10 ring-2 ring-white ring-opacity-100 ring-offset-2 ring-offset-blue-500'
                                            : ''
                                        }
                                            ${i === 0 ? 'rounded-l-lg' : ''}
                                            ${i === randomInvestementPercentileOptions.length - 1 ? 'rounded-r-lg' : ''}
                  ${checked ? 'bg-blue-50 text-blue-600 font-medium' : 'font-medium bg-white text-gray-600'
                                        }
                    relative flex cursor-pointer px-3 py-3 focus:outline-none text-sm border border-gray-100`
                                    }
                                >
                                    <RadioGroup.Label as="p">
                                        {percentile}
                                    </RadioGroup.Label>
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>


                    </div>
                </div>

            </div>


            <div className='max-w-6xl mx-auto px-6 py-6 border-b border-gray-100'>
                <Button onClick={()=>setModelingLoading(true)} loading={modelingLoading}>Submit modeling</Button>
                </div>
  
        </div>)
}