import React from 'react'
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ViewWordsView from './views/ViewWordsView';
import AddWordsView from './views/AddWordsView';
import FilterWordsView from './views/FilterWordsView';
import FundOutlook from './apps/fund-outlook';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<div>Demo apps by dkrasniy</div>} />

      <Route path="/fund-outlook/" element={<FundOutlook/>} />

      <Route path="/word-matcher/" element={<ViewWordsView />} />
      <Route path="/word-matcher/add" element={<AddWordsView />} />
      <Route path="/word-matcher/filter" element={<FilterWordsView />} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
